import React, { useContext } from 'react'
import { ModalSectionBaseProps, ModalSectionHubspotForm } from '../../../../../types/modal-sections'
import ModalContext from '../../Modal.context'

import HubspotFormComponent from '../../../HubspotForm'

export interface HubspotFormProps extends ModalSectionBaseProps {
  data: ModalSectionHubspotForm
}

const HubspotForm: React.FC<HubspotFormProps> = ({ data }) => {
  const { closeModal } = useContext(ModalContext)
  const { hubspotForm } = data
  return (
    <HubspotFormComponent
      region={hubspotForm.region}
      portalId={hubspotForm.portalId}
      formId={hubspotForm.formId}
      onFormSubmitted={() => setTimeout(() => closeModal(), 3000)}
    />
  )
}

export default HubspotForm
